import React, { useState, useEffect } from 'react';
import { Button, Container, Rating, Typography } from '@mui/material';
//import firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/functions';
import gifimage from './tick1.gif'

import ReactStarRatings from 'react-star-ratings';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import axios from 'axios';
function App() {
  const [valueForMoney, setValueForMoney] = useState(0);
  const [speedOfDelivery, setSpeedOfDelivery] = useState(0);
  const [quality, setQuality] = useState(0);
  const [thanksView,setthanksview]=useState(false)
  const [labels, setData] = useState([]);
  const [ratings, setRatings] = useState({});
  const [businessdata,setbusinessdata]=useState([])
  const [customitem,setcustomitem]=useState('')
  const [showloader,setshowloader]=useState(false)
  const publicurl=process.env.REACT_APP_API_JIFFYBOOKPUBLIC_URL
  const { id } = useParams();
  const [thankstext,setthankstext]=useState('')
  const [lang,countrycode, BID256, Rdate, RID] = id.split('_');
 // console.log(lang,BID256,Rdate,RID)
  const handleSubmit = async () => {
    try {
  //    const addRating = firebase.functions().httpsCallable('addRating');
    //  await addRating({ valueForMoney, speedOfDelivery, quality });
    let posturl=''
    //setshowloader(true);
    
    setthanksview(true)
    setTimeout(() => {
      setshowloader(false)
    setthankstext('Rating successfully done')
    }, 2000);
    
    if(countrycode=='EE'){
      posturl=process.env.REACT_APP_API_POSTRATINGEE_URL
    }
    if(countrycode=='IN'){
      posturl=process.env.REACT_APP_API_POSTRATINGIN_URL
    }
      console.log('Rating submitted successfully!');
      console.log(posturl)
      axios({
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
       // url:'https://us-central1-jiffybook-app.cloudfunctions.net/writeratinglabeldata',
       url:posturl, 
       data:{
          BID256:BID256,
          Rdate:Rdate,
          RID:RID,
          Rating:ratings

        },
      
      })
      
      setthanksview(true)
      
    console.log(ratings)
    } catch (error) {
      console.error('Error submitting rating:', error);
    }
  };
  
  const handleRatingChange = (labelId, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [labels[labelId].strLabel]: value
    }));
    console.log(ratings);
  };
  useEffect(() => {
  fetchratingData()
    ;
  }, []);

  const fetchratingData = async () => {
    try {
      console.log('countrycode',countrycode)
     //const response=await fetch(`${apiurl}?parameter=${id}`) 
    // const response=await fetch(' https://us-central1-jiffybook-app.cloudfunctions.net/getratinglabeldata?parameter=en_6dddfb32d8dead019928acbc17af6861fd3049125e1762e79009dc42cde8d196_20240114_EEyDDR4fbU558CDqHO8H')
      if(countrycode=='EE'){
    const response=await fetch( `${process.env.REACT_APP_API_RATINGLABELEE_URL}?parameter=${id}`)
    
     const result = await response.json();
       // console.log(response)
       setData(result);
       setcustomitem(result.customitemname)
      if (result.rating=='Rating') {
        setData(result.label);
        
       setcustomitem(result.customitemname)
        console.log(result.label,result.customitemname)
        
      } else {
        setthanksview(true)
        setthankstext(result.rating)
        console.error('Error fetching data:', result.error);
      }
    }
    else if(countrycode=='IN'){
      const response=await fetch(`${process.env.REACT_APP_API_RATINGLABELIN_URL}?parameter=${id}`)
      
     const result = await response.json();
     console.log(result)
      if (result.rating=='Rating') {
        setData(result.label);
        
       setcustomitem(result.customitemname)
        console.log(result.label,result.customitemname)
        
      } else {
        setthanksview(true)
        setthankstext(result.rating)
        console.error('Error fetching data:', result.error);
      }
    }
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
   
  };
  const showLoader = () => {
    return (
      <div className="bouncing-ball">
        
      
      <div className="bouncing-ball">
        
        </div>
      </div>
    );
  };
  const [loop, setLoop] = useState(true);

  const handleLoad = () => {
    setLoop(false); // Disable looping after the GIF has loaded
  };
  const CustomHeartIcon = (props) => {
    return <span dangerouslySetInnerHTML={{ __html: '&#x2764;' }} />;
  };
  return (
  
    <Container className={'container'}>
         {thanksView==false ? (

        <div className="ratingContainer">
          {customitem==''?(
     <div className="bouncing-ball">
       {showLoader()}
    </div>):null}
          <Typography variant="div" className={'ratingTitle'}>
          {customitem}
          </Typography>
          <div className={'ratingContainer'}>
            
            {Object.keys(labels).map((labelId) => (
              <Typography key={labelId} component="div">
                <Typography style={{fontSize:'14px',marginTop:5,marginBottom:5}} component="div">
                  {labels[labelId][`strLabel_${lang}`]?labels[labelId][`strLabel_${lang}`]:labels[labelId][`strLabel`]}
                </Typography>
                
          <div className="custom-rating">
          <ReactStarRatings
            rating={ratings[labels[labelId].strLabel] || 0}
            starRatedColor="#e75600"
            changeRating={(newValue) => handleRatingChange(labelId, newValue)}
            numberOfStars={5}
            name={labels[labelId].strLabel}
            starDimension="35px"
            starSpacing="12px"
          />
                </div>
              </Typography>
            ))}
          </div>
        
          {customitem!=''?(
      <div 
        className={'submitButton'}>
      <Button
        variant="contained"
        onClick={handleSubmit}
        style={{backgroundColor:'#e75600',borderRadius:'30px',fontSize:'14px',fontWeight:'bold'}}
      >
        &#10003;
      </Button>
      </div>):null}
      </div>
      ): (
        // Show the "Thank You" message when thanksView is true
        <div className="ratingContainer">
        {showloader==true&&thankstext==''?(
          <div className="bouncing-ball">
            {showLoader()}
         </div>):null}
         <div class="popper">
    <div class="checkmark"></div>
  </div>
        </div>
      )}
    </Container>
    
  );
};

export default App;